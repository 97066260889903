import ServiceBase from "@/api/core";

const baseURL = process.env.VUE_APP_API_BASE_URL;

export default class VenueService extends ServiceBase {
  static requestForApproval() {
    const url = "/private/venue/request-for-approval";

    return this.callApi({
      method: "POST",
      url,
      baseURL,
    });
  }

  static approve(approved) {
    const url = "/private/venue/approve";

    return this.callApi({
      method: "POST",
      url,
      baseURL,
      data: {
        approved,
      },
    });
  }
}
