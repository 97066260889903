<template>
  <div class="edit-venue-page">
    <BackTitle confirm-click @click="goBack">Edit the venue</BackTitle>
    <Guard permission="venues.edit" v-slot="{ isAvailable }">
      <VenueForm
        :value="venue"
        submitButtonText="Save"
        :is-submitting="isSubmitting"
        :is-approving="isApproving"
        :is-rejecting="isRejecting"
        :is-disabled="!isAvailable"
        @submit="save"
        @request-for-approval="requestForApproval"
        @approve="approve"
      />
    </Guard>
  </div>
</template>

<script>
import VenueForm from "@/components/venues/VenueForm";
import { mapActions, mapState } from "vuex";
import BackTitle from "@/components/common/BackTitle";
import VenueService from "@/api/services/VenueService";
import alert from "@/plugins/alert";
import dialog from "@/plugins/dialog";
import { VenueStatusEnum } from "@/helpers/enums";
import Guard from "@/components/common/Guard";
import { DEFAULT_PAYMENT_METHODS } from "@/helpers/const";

export default {
  name: "EditVenuePage",
  components: { Guard, BackTitle, VenueForm },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      isApproving: false,
      isRejecting: false,
    };
  },
  computed: {
    ...mapState({
      venue(storeState) {
        const {
          id,
          name,
          companyName,
          companySite,
          country,
          timezone,
          address,
          phone,
          email,
          firstDayOfWeek = 0,
          paymentMethods = DEFAULT_PAYMENT_METHODS,
          timeFormat = 12,
          dateFormat = "MM/DD/YYYY",
          widget = "yoreferee",
          coachNote = "",
          coachNoteCutoffTime = 48,
          registrationCodePrefix = "",
          phoneFormat = "+X (XXX) XXX-XX-XX",
          moneyFormat = "${{amount}}",
          logo,
          youtubeLink,
          instagramLink,
          xLink,
          bookingLifeTime = 10,
        } = storeState.venues.selectedVenue;
        return {
          id,
          name,
          companyName,
          companySite,
          country,
          timezone,
          address,
          phone,
          email,
          coachNote,
          coachNoteCutoffTime,
          firstDayOfWeek,
          paymentMethods,
          timeFormat,
          dateFormat,
          widget,
          registrationCodePrefix,
          phoneFormat,
          moneyFormat,
          logo,
          youtubeLink,
          instagramLink,
          xLink,
          bookingLifeTime,
        };
      },
    }),
  },
  methods: {
    ...mapActions({
      updateVenue: "venues/updateVenue",
      fetchVenue: "venues/fetchVenue",
    }),
    async save({ id, ...data }) {
      try {
        this.isSubmitting = true;
        await this.updateVenue({ id, data });
        await this.fetchVenue();
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
    async requestForApproval() {
      const confirmed = await dialog.confirm({
        title: "Are you sure?",
        message: `Approval will be requested for the venue and it will receive the ${VenueStatusEnum.PENDING} status`,
        okText: "Request for approval",
        cancelText: "Cancel",
      });
      if (confirmed) {
        try {
          this.isApproving = true;
          await VenueService.requestForApproval();
          await this.fetchVenue();
          alert.open({
            message: "An approve was requested",
            variant: "success",
          });
        } finally {
          this.isApproving = false;
        }
      }
    },
    async approve(approved) {
      const confirmed = await dialog.confirm({
        title: "Are you sure?",
        message: `The venue will be ${
          approved ? "approved" : "rejected"
        } and will receive the ${
          approved ? VenueStatusEnum.LIVE : VenueStatusEnum.INACTIVE
        } status`,
        okText: approved ? "Approve" : "Reject",
        cancelText: "Cancel",
      });
      if (confirmed) {
        try {
          if (approved) this.isApproving = true;
          else this.isRejecting = true;
          await VenueService.approve(approved);
          await this.fetchVenue();
          alert.open({
            message: `The request was ${
              approved ? "approved" : "rejected"
            } successfully`,
            variant: "success",
          });
        } finally {
          this.isApproving = false;
          this.isRejecting = false;
        }
      }
    },
    goBack() {
      this.$router.push({
        name: "Settings",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-venue-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
</style>
